export const constructHeroGradient = (angle: number) =>
  `linear-gradient(${angle}deg, #13B1E5, #A91AE7)`

export const colors = {
  clear: 'rgba(0, 0, 0, 0)',
  white: '#FFF',
  black: '#333',
  silverLight: '#F7F7F7',
  silverMedium: '#EAEAEA',
  silverDark: '#D8D8D8',
  grayMedium: '#9B9B9B',
  grayDark: '#656565',
  charcoal: '#4A4A4A',
  mint: '#75D6AC',
  mintAlpha10: '#F1FAF6',
  mintAlpha17: '#E8F8F1',
  mintDark: '#3EBC85',
  stripsRemainingGreen: '#7DC69C',
  brandBlue: '#0066CC',
  brandBlueDark: '#003E7D',
  blueMarine: '#5D8FDF',
  blueMarineLight: '#BFD9F2',
  blueMarineDark: '#3D70C4',
  blueMarineAlpha5: '#F6F9FD',
  blueMarineAlpha10: '#EEF3FB',
  blueMarineAlpha20: '#DFE9F9',
  blueMarineAlpha27: '#d3e0f6',
  metalBlue: '#4172A2',
  skyBlue: '#64BAE9',
  skyBlueAlpha13: '#EBF6FC',
  red: '#FF7B7B',
  orange: '#F5A623',
  orangeAlpha20: '#FDEDD3',
  trafficRed: '#CF021B',
  trafficGreen: '#7ED321',
  trafficGreenAlpha30: '#D8F1BC',
  trafficRedPastel: '#FBEDEF',
  transparentGrayMedium: 'rgba(198, 198, 198, 0.5)',
  heroGradient: constructHeroGradient(93),
  secondaryGradient: 'linear-gradient(-180deg, #37DBE0, #629CE9)',
  redGradient: 'linear-gradient(-180deg, #FF7B7B, #DC4949)',
  orangeGradient: 'linear-gradient(-180deg, #FFAC73, #F39552)',
  greenGradient: 'linear-gradient(-180deg, #75D6AC, #3EBC85)',
}

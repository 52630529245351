import * as styledComponents from 'styled-components'

import { borderRadius } from './border-radius'
import { createGlobalStyle } from 'styled-components'
import normalizeCss from './normalize.css'

import { animations } from './animations'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { flexboxgrid } from './flexboxgrid'
import { space, spacing } from './spacing'
import { transitionEasing, transitions, transitionSpeed } from './transitions'
import { fontSize, fontSizes, letterSpacing } from './typography'
import { fontWeights } from './weight'
import { zIndexes } from './z-index'

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  ThemeInterface
>

export interface ThemeInterface {
  flexboxgrid: any
  animations: { [key: string]: styledComponents.Keyframes }
  borderRadius: string[]
  breakpoints: number[]
  colors: { [key: string]: string }
  fontSize: { [key: string]: string }
  fontSizes: number[]
  fontWeights: { [key: string]: number }
  letterSpacing: { [key: string]: string }
  spacing: { [key: string]: string }
  space: string[]
  textStyles: any
  transitions: { [key: string]: string }
  transitionEasing: { [key: string]: string }
  transitionSpeed: { [key: string]: string }
  zIndexes: { [key: string]: number }
  fontFamily: string
}

export const theme: ThemeInterface = {
  flexboxgrid,
  animations,
  borderRadius,
  breakpoints,
  colors,
  fontSize,
  fontSizes,
  fontWeights,
  letterSpacing,
  space,
  spacing,
  textStyles: {
    caps: {
      textTransform: 'uppercase',
    },
    italic: {
      fontStyle: 'italic',
    },
  },
  transitionEasing,
  transitionSpeed,
  transitions,
  zIndexes,
  fontFamily: `'HelveticaNeue-Light', Helvetica Neue Light,
    'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;`,
}

export const createStyledComponent = <Tag extends keyof JSX.IntrinsicElements>(
  tag: Tag,
) => styled<Tag>(tag)

export default styled
export { css, keyframes, ThemeProvider }

export const GlobalStyle = createGlobalStyle`
  ${normalizeCss}
  html {
    font-family: ${theme.fontFamily};
    line-height: 1.15;
    text-size-adjust: 100%;
  }
  html, body, #___gatsby, #gatsby-focus-wrapper, #gatsby-focus-wrapper > main {
    height: 100%;
  }
  * {
    box-sizing: border-box;
  }
  body {
    color: #000;
  }
  input[type='submit'],
  button {
    cursor: pointer;
  }
`

import React from 'react'

import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme } from '../../theme'

export const Layout: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <main>{children}</main>
    </ThemeProvider>
  )
}

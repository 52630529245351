export const flexboxgrid = {
  // Defaults
  gridSize: 12, // columns
  gutterWidth: 0, // rem
  outerMargin: 0, // rem
  mediaQuery: 'only screen',
  container: {
    sm: 46, // rem
    md: 61, // rem
    lg: 100, // rem
  },
  breakpoints: {
    xs: 0, // em
    sm: 48, // em
    md: 64, // em
    lg: 101, // em
  },
}
